
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faqFUklVnvV8ov40yZ0Ft0__qqYt_45x5nVjUYgM5ku3aX2cMeta } from "/builds/r3013/public-website-front/pages/faq.vue?macro=true";
import { default as blogDYtruemgaZYMvTTcLxWEd_45ku5gNIFrWMM7U6pwH9S_8Meta } from "/builds/r3013/public-website-front/pages/blog.vue?macro=true";
import { default as freeg6Xka4i_2zBmcAgx5mCKBqYyykZOH3_45b9Bs3tH3Rr7QMeta } from "/builds/r3013/public-website-front/pages/free.vue?macro=true";
import { default as indexPgwIt1Gmv3nYnVPO48Qp04sxQE_45jnlQ3cs3CjcfMQ7AMeta } from "/builds/r3013/public-website-front/pages/index.vue?macro=true";
import { default as licenseUxs2V3wiArSTJgrVLFJAfmT0ydILVW2EkuiqBQL5WukMeta } from "/builds/r3013/public-website-front/pages/license.vue?macro=true";
import { default as privacykYKbywwDAyedHQEJJXXoJvOPmEKVlBrePHUXvAKXGosMeta } from "/builds/r3013/public-website-front/pages/privacy.vue?macro=true";
import { default as reviewsh1YbCiQKd6Lp5k8uR54U8PI9HYiRMSf1ekg_45ZBLolzMMeta } from "/builds/r3013/public-website-front/pages/reviews.vue?macro=true";
import { default as featuresNAB3BKvc1a3WMsd3FNVVFR19B30uuONA5D5Tw2nBYe4Meta } from "/builds/r3013/public-website-front/pages/features.vue?macro=true";
import { default as medicineeo070LbuDZSCMTFyarD5np5yqJvoYFfJdvjbppP0Mw8Meta } from "/builds/r3013/public-website-front/pages/medicine.vue?macro=true";
import { default as _91_46_46_46slug_936MtMGGPCXsO7VWhzNVCYwLd8vZ4JGjvQko3Mp8HSaRMMeta } from "/builds/r3013/public-website-front/pages/[...slug].vue?macro=true";
import { default as licenseipOlfMHwoAcR4_45VTDCC0JyiABZfXpr02PXyaUg2di0hIQMeta } from "/builds/r3013/public-website-front/pages/licenseip.vue?macro=true";
import { default as ammJhL5UKfpdRUFUA2Xpnuofioqssoqe2vw8xc39MAwmvMMeta } from "/builds/r3013/public-website-front/pages/events/amm.vue?macro=true";
import { default as mbsuG1sA_hWLtd3lrHj5qCtK9A4WmFP3yvYm6aBLtVCsX0Meta } from "/builds/r3013/public-website-front/pages/events/mbs.vue?macro=true";
import { default as uisHVbB5Q7tWdsIJLueclce2Uvfuzk9LWAzdS8lmXKU_45awMeta } from "/builds/r3013/public-website-front/pages/events/uis.vue?macro=true";
import { default as efirp177Yzr_45Tk5MRJtML2ZkpUdRkr0QipW9Jowm52PkaDUMeta } from "/builds/r3013/public-website-front/pages/events/efir.vue?macro=true";
import { default as gm24s9y4A6ChjMp6hVvM71IiP2RUWiVswm89erMDyKw_chgMeta } from "/builds/r3013/public-website-front/pages/events/gm24.vue?macro=true";
import { default as enjoyAkNTMoLaWOYDgUc6aKulPlCyQDDuNN4AGMErcNovIjkMeta } from "/builds/r3013/public-website-front/pages/events/enjoy.vue?macro=true";
import { default as eqivaTVCcxx34vjXdgipBD_45fzemyLz4M51QVL5zOhxDpO9sgMeta } from "/builds/r3013/public-website-front/pages/events/eqiva.vue?macro=true";
import { default as start_45_458NEbz2dkBQUtQcsuBBGor_ON3pJru_uadNJTF8vHwMeta } from "/builds/r3013/public-website-front/pages/events/start.vue?macro=true";
import { default as apcmedKSd3UATl_45kLuTKSq0_45k7jFlWF6BVmJ6mshBC_QzaYS8Meta } from "/builds/r3013/public-website-front/pages/events/apcmed.vue?macro=true";
import { default as courserFZNOVk5bGLRnQJa0kQsvKdNP9mQJqeA7uBDE58p9BoMeta } from "/builds/r3013/public-website-front/pages/events/course.vue?macro=true";
import { default as efir077L7eeV6DSn3WMSZGb6vZV_FZx_45h_0_bF0VtGEeEO2uQMeta } from "/builds/r3013/public-website-front/pages/events/efir07.vue?macro=true";
import { default as efir25W3Sa88xJzQo2m8mNPLBPfG_SF49xkAhDZieQtyebZt0Meta } from "/builds/r3013/public-website-front/pages/events/efir25.vue?macro=true";
import { default as shmb25MTSKObRdqJrKV12T8x113hzMnZHRQppMnGlDkCitJcoMeta } from "/builds/r3013/public-website-front/pages/events/shmb25.vue?macro=true";
import { default as youtubel97Kg7K09Uz_0j3kNOYMd6p0Qpkk9KLHEFuUwgnz2v4Meta } from "/builds/r3013/public-website-front/pages/events/youtube.vue?macro=true";
import { default as auditgeoitcp9mMvYnwxM6QXMAz4UminSTj3LQncFdCIqpzQD0UMeta } from "/builds/r3013/public-website-front/pages/events/auditgeo.vue?macro=true";
import { default as clubshmbfQW36eELRxWhcTUzNrNd4_45hUb_45Lpf9Lrn0DeoxagBWsMeta } from "/builds/r3013/public-website-front/pages/events/clubshmb.vue?macro=true";
import { default as youtubeaXYAf5yzNkviHl6JSzc6jjWkEwzGM7I89ILRIL5rj7vEMeta } from "/builds/r3013/public-website-front/pages/events/youtubea.vue?macro=true";
import { default as vademecumPw6GWP5k1vTooKqvcBkK8zfduxD5dTusUCLO8_cIQrYMeta } from "/builds/r3013/public-website-front/pages/events/vademecum.vue?macro=true";
import { default as growthmed24v8mVI0cWlv6sn2PgvbGq2f_jnne_RwOMhkBrdhGDScQMeta } from "/builds/r3013/public-website-front/pages/events/growthmed24.vue?macro=true";
import { default as vademecum2023HGxnK548d6dbf1OLSXJLvZvXdp3Y6ZKDr_455o5j6l1C8Meta } from "/builds/r3013/public-website-front/pages/events/vademecum2023.vue?macro=true";
import { default as _91_46_46_46slug_93QuOWsc33b3Qan_45byeQJk87yElwBDBGK2ReTp_45swU2zIMeta } from "/builds/r3013/public-website-front/pages/rating/org/[...slug].vue?macro=true";
import { default as vademecum_live_45kYWZCZimYKBhDNymwmUz17h7D95YED6QDgFqTBvdiMMeta } from "/builds/r3013/public-website-front/pages/events/vademecum_live.vue?macro=true";
import { default as med_marketing20247DmpsMFBiXHQwdln1Oky1l_8jPwWEUtBdYHPQ4SUtnwMeta } from "/builds/r3013/public-website-front/pages/events/med_marketing2024.vue?macro=true";
import { default as _91_46_46_46slug_93_46client9MRQHNN1oHfljBX6_45JZAH_sM0OwxcwcuDZ2zt77e78cMeta } from "/builds/r3013/public-website-front/pages/rating/embed/[...slug].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/r3013/public-website-front/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91_91seoCompanyType_93_93v6IFSXaOYPQB8t8aQpuxe7jC8hUumQ9G8stDdoeHeOkMeta } from "/builds/r3013/public-website-front/pages/rating/[[city]]/[[companyType]]/[[seoCity]]/[[seoCompanyType]].vue?macro=true";
export default [
  {
    name: "faq",
    path: "/faq",
    component: () => import("/builds/r3013/public-website-front/pages/faq.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/builds/r3013/public-website-front/pages/blog.vue")
  },
  {
    name: "free",
    path: "/free",
    component: () => import("/builds/r3013/public-website-front/pages/free.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/r3013/public-website-front/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/builds/r3013/public-website-front/pages/license.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/builds/r3013/public-website-front/pages/privacy.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/builds/r3013/public-website-front/pages/reviews.vue")
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/builds/r3013/public-website-front/pages/features.vue")
  },
  {
    name: "medicine",
    path: "/medicine",
    component: () => import("/builds/r3013/public-website-front/pages/medicine.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/r3013/public-website-front/pages/[...slug].vue")
  },
  {
    name: "licenseip",
    path: "/licenseip",
    component: () => import("/builds/r3013/public-website-front/pages/licenseip.vue")
  },
  {
    name: "events-amm",
    path: "/events/amm",
    component: () => import("/builds/r3013/public-website-front/pages/events/amm.vue")
  },
  {
    name: "events-mbs",
    path: "/events/mbs",
    component: () => import("/builds/r3013/public-website-front/pages/events/mbs.vue")
  },
  {
    name: "events-uis",
    path: "/events/uis",
    component: () => import("/builds/r3013/public-website-front/pages/events/uis.vue")
  },
  {
    name: "events-efir",
    path: "/events/efir",
    component: () => import("/builds/r3013/public-website-front/pages/events/efir.vue")
  },
  {
    name: "events-gm24",
    path: "/events/gm24",
    component: () => import("/builds/r3013/public-website-front/pages/events/gm24.vue")
  },
  {
    name: "events-enjoy",
    path: "/events/enjoy",
    component: () => import("/builds/r3013/public-website-front/pages/events/enjoy.vue")
  },
  {
    name: "events-eqiva",
    path: "/events/eqiva",
    component: () => import("/builds/r3013/public-website-front/pages/events/eqiva.vue")
  },
  {
    name: "events-start",
    path: "/events/start",
    component: () => import("/builds/r3013/public-website-front/pages/events/start.vue")
  },
  {
    name: "events-apcmed",
    path: "/events/apcmed",
    component: () => import("/builds/r3013/public-website-front/pages/events/apcmed.vue")
  },
  {
    name: "events-course",
    path: "/events/course",
    component: () => import("/builds/r3013/public-website-front/pages/events/course.vue")
  },
  {
    name: "events-efir07",
    path: "/events/efir07",
    component: () => import("/builds/r3013/public-website-front/pages/events/efir07.vue")
  },
  {
    name: "events-efir25",
    path: "/events/efir25",
    component: () => import("/builds/r3013/public-website-front/pages/events/efir25.vue")
  },
  {
    name: "events-shmb25",
    path: "/events/shmb25",
    component: () => import("/builds/r3013/public-website-front/pages/events/shmb25.vue")
  },
  {
    name: "events-youtube",
    path: "/events/youtube",
    component: () => import("/builds/r3013/public-website-front/pages/events/youtube.vue")
  },
  {
    name: "events-auditgeo",
    path: "/events/auditgeo",
    component: () => import("/builds/r3013/public-website-front/pages/events/auditgeo.vue")
  },
  {
    name: "events-clubshmb",
    path: "/events/clubshmb",
    component: () => import("/builds/r3013/public-website-front/pages/events/clubshmb.vue")
  },
  {
    name: "events-youtubea",
    path: "/events/youtubea",
    component: () => import("/builds/r3013/public-website-front/pages/events/youtubea.vue")
  },
  {
    name: "events-vademecum",
    path: "/events/vademecum",
    component: () => import("/builds/r3013/public-website-front/pages/events/vademecum.vue")
  },
  {
    name: "events-growthmed24",
    path: "/events/growthmed24",
    component: () => import("/builds/r3013/public-website-front/pages/events/growthmed24.vue")
  },
  {
    name: "events-vademecum2023",
    path: "/events/vademecum2023",
    component: () => import("/builds/r3013/public-website-front/pages/events/vademecum2023.vue")
  },
  {
    name: "rating-org-slug",
    path: "/rating/org/:slug(.*)*",
    component: () => import("/builds/r3013/public-website-front/pages/rating/org/[...slug].vue")
  },
  {
    name: "events-vademecum_live",
    path: "/events/vademecum_live",
    component: () => import("/builds/r3013/public-website-front/pages/events/vademecum_live.vue")
  },
  {
    name: "events-med_marketing2024",
    path: "/events/med_marketing2024",
    component: () => import("/builds/r3013/public-website-front/pages/events/med_marketing2024.vue")
  },
  {
    name: "rating-embed-slug",
    path: "/rating/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93_46client9MRQHNN1oHfljBX6_45JZAH_sM0OwxcwcuDZ2zt77e78cMeta || {},
    component: () => createClientPage(() => import("/builds/r3013/public-website-front/pages/rating/embed/[...slug].client.vue"))
  },
  {
    name: "rating-city-companyType-seoCity-seoCompanyType",
    path: "/rating/:city?/:companyType?/:seoCity?/:seoCompanyType?",
    meta: _91_91seoCompanyType_93_93v6IFSXaOYPQB8t8aQpuxe7jC8hUumQ9G8stDdoeHeOkMeta || {},
    component: () => import("/builds/r3013/public-website-front/pages/rating/[[city]]/[[companyType]]/[[seoCity]]/[[seoCompanyType]].vue")
  }
]